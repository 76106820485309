import * as React from "react";
import FileInput from "../components/form.js"

// styles
const pageStyles = {
  color: "#232129",
  padding: 50,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Existec</title>
      <h1>Proof of Concept</h1>
      <p>File-upload initiated screening form.</p>
      <FileInput />
    </main>
  );
};

export default IndexPage;
