function buildJson(inputObject, sampleJson, screeningRef) {
  var finalJson = sampleJson;
  var reference = [];
  var description = [];

  inputObject = JSON.parse(JSON.stringify(inputObject));

  // Operator
  if (inputObject.hasOwnProperty("Operator")) {
    finalJson.sailings[0].vessel["code"] = inputObject["Operator"];
  }

  // TPDoc value
  if (inputObject.hasOwnProperty("TPDoc")) {
    //
  }

  // Case Reference
  if (inputObject.hasOwnProperty("Shipment Number")) {
    reference.push(inputObject["Shipment Number"]);
  }
  reference.push(screeningRef);
  finalJson.reference = reference.join("-");

  // Booked by
  if (inputObject.hasOwnProperty("Booked By Customer Code")) {
    finalJson.parties["booked-by"].code =
      inputObject["Booked By Customer Code"];
  }
  if (inputObject.hasOwnProperty("Booked By Customer Name")) {
    finalJson.parties["booked-by"].name =
      inputObject["Booked By Customer Name"];
  }

  // Shipper
  if (inputObject.hasOwnProperty("Shipper Customer Code")) {
    finalJson.parties["shipper"].code = inputObject["Shipper Customer Code"];
  }
  if (inputObject.hasOwnProperty("Shipper Customer Name")) {
    finalJson.parties["shipper"].name = inputObject["Shipper Customer Name"];
  }
  if (inputObject.hasOwnProperty("Shipper Address Line1")) {
    finalJson.parties["shipper"]["address-line1"] =
      inputObject["Shipper Address Line1"];
  }
  if (inputObject.hasOwnProperty("Shipper Address Line2")) {
    finalJson.parties["shipper"]["address-line2"] =
      inputObject["Shipper Address Line2"];
  }

  // Consignee
  if (inputObject.hasOwnProperty("Consignee Customer Code")) {
    finalJson.parties["consignee"].code =
      inputObject["Consignee Customer Code"];
  }
  if (inputObject.hasOwnProperty("Consignee Customer Name")) {
    finalJson.parties["consignee"].name =
      inputObject["Consignee Customer Name"];
  }
  if (inputObject.hasOwnProperty("Consignee Address Line1")) {
    finalJson.parties["consignee"]["address-line1"] =
      inputObject["Consignee Address Line1"];
  }

  // First Notify
  if (inputObject.hasOwnProperty("First Notify Customer Code")) {
    finalJson.parties["first-notify"].code =
      inputObject["First Notify Customer Code"];
  }
  if (inputObject.hasOwnProperty("First Notify Customer Name")) {
    finalJson.parties["first-notify"].name =
      inputObject["First Notify Customer Name"];
  }
  if (inputObject.hasOwnProperty("First Notify Address Line1")) {
    finalJson.parties["first-notify"]["address-line1"] =
      inputObject["First Notify Address Line1"];
  }

  // Additional Notify
  if (inputObject.hasOwnProperty("Second Notify Customer Code")) {
    finalJson.parties["additional-notify"].code =
      inputObject["Second Notify Customer Code"];
  }
  if (inputObject.hasOwnProperty("Second Notify Customer Name")) {
    finalJson.parties["additional-notify"].name =
      inputObject["Second Notify Customer Name"];
  }
  if (inputObject.hasOwnProperty("Second Notify Address Line1")) {
    finalJson.parties["additional-notify"]["address-line1"] =
      inputObject["Second Notify Address Line1"];
  }

  // Cargo
  description = [];
  if (inputObject.hasOwnProperty("Commodity Code")) {
    description.push({
      $id: "CommodityCode",
      source: "Commodity Code",
      text: inputObject["Commodity Code"],
    });
  }
  if (inputObject.hasOwnProperty("Commodity Description")) {
    description.push({
      $id: "CommodityDescription",
      source: "Commodity Description",
      text: inputObject["Commodity Description"],
    });
  }
  if (inputObject.hasOwnProperty("Cargo Documentation Description")) {
    description.push({
      $id: "CargoDocumentationDescription",
      source: "Cargo Documentation Description",
      text: inputObject["Cargo Documentation Description"],
    });
  }
  if (description.length != 0) {
    finalJson["cargo-transport-units"][0].cargo[0].description = description;
  } else {
    finalJson["cargo-transport-units"][0].cargo[0].description = [
      {
        $id: "CargoDescription",
        source: "Cargo Description",
        text: "PLACEHOLDER",
      },
    ];
  }

  // Contractual Customer
  if (inputObject.hasOwnProperty("Contractual Customer Customer Code")) {
    finalJson.parties["contractual-customer"] =
      inputObject["contractual-customer"];
  }
  if (inputObject.hasOwnProperty("Contractual Customer Customer Name")) {
    finalJson.parties["booked-by"] = inputObject["Booked By Customer Code"];
  }

  if (inputObject.hasOwnProperty("HAZ")) {
    //
  }

  // Stages
  if (inputObject.hasOwnProperty("POR Country Name")) {
    finalJson.sailings[0].stages[0].country.name = inputObject["POR Country Name"];
    finalJson.sailings[0].stages[0].country.code = inputObject["POR Country Name"];
  }
  if (inputObject.hasOwnProperty("POD Country Name")) {
    finalJson.sailings[0].stages[1].country.name = inputObject["POD Country Name"];
    finalJson.sailings[0].stages[1].country.code = inputObject["POD Country Name"];
  }

  return finalJson;
}

async function CreateScreeningRequest(token, caseRef, data) {
  var dataAsJson = {};

  const sampleJson = {
    $schema: "http://detect.hazcheck.com/validation/shipment.schema.json",
    "imdg-amendment": 40,
    reference: "TESTING-AGAIN",
    sailings: [
      {
        stages: [
          {
            type: "Origin",
            country: {
              code: "XXXXX",
              name: "XXXXX",
            },
            port: {
              code: "XXXXX",
              name: "XXXXX",
            },
            date: "2022-09-05",
          },
          {
            type: "Final",
            country: {
              code: "XXXXX",
              name: "XXXXX",
            },
            port: {
              code: "XXXXX",
              name: "XXXXX",
            },
          },
        ],
        type: "Cargo",
        vessel: {
          code: "TESTING",
        },
      },
    ],
    "cargo-transport-units": [
      {
        $id: "XXX-TEST",
        cargo: [
          {
            $schema: "#commodity",
            description: [
              {
                $id: "CargoDescription",
                source: "Cargo Description",
                text: "PLACEHOLDER",
              },
            ],
          },
        ],
      },
    ],
    parties: {
      "additional-notify": {
        code: "UNKNOWN",
        name: "UNKNOWN",
      },
      "booked-by": {
        code: "UNKNOWN",
        name: "UNKNOWN",
      },
      consignee: {
        code: "UNKNOWN",
        name: "UNKNOWN",
      },
      "contractual-customer": {
        code: "UNKNOWN",
        name: "UNKNOWN",
      },
      "first-notify": {
        code: "UNKNOWN",
        name: "UNKNOWN",
      },
      shipper: {
        code: "UNKNOWN",
        name: "UNKNOWN",
      },
    },
  };

  if (data.name.substring(data.name.length - 5) != ".json") {
    console.log("Non-json filetype detected.");
    // convert the uploaded xlsx/xls file to JSON
    var dataAsJson = await ConvertToJson(data);

    alert(`File Accepted with ${dataAsJson.length} rows.`);
    // translate the data to the working JSON
    for (var i = 0; i < dataAsJson.length; i++) {
      var jsonToScreen = buildJson(dataAsJson[i], sampleJson, caseRef);
      var reqCaseRef = jsonToScreen.reference;
      jsonToScreen = JSON.stringify(jsonToScreen);
      SendScreeningRequest(token, reqCaseRef, jsonToScreen);
    }
  } else {
    console.log("Json filetype detected.");
    alert(`File Accepted with ${dataAsJson.length} rows.`);
    readFileObject(token, caseRef, data);
  }
}

async function ConvertToJson(data) {
  var formdata = new FormData();
  formdata.append("data", data);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      "https://weu-exis-demo-xls-screening-fa.azurewebsites.net/api/JSONConverter",
      requestOptions
    );
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
}

function SendScreeningRequest(token, caseRef, jsonData) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("X-Correlation-Id", caseRef);
  myHeaders.append("Authorization", "Bearer " + token);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: jsonData,
    redirect: "follow",
  };

  fetch(
    "https://api.demo.hazcheckdetect.com/screen?policy=6f31190a-7d27-48bf-d55c-08da506ffeb4",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));

  console.log("Sent to screening service.");
}

function readFileObject(token, caseRef, file) {
  var jsonData;
  const reader = new FileReader();
  reader.readAsText(file);
  reader.addEventListener("load", (e) => {
    jsonData = JSON.parse(e.target.result);
    jsonData.reference = caseRef;
    jsonData = JSON.stringify(jsonData);
    console.log(jsonData);
    SendScreeningRequest(token, caseRef, jsonData);
  });
}

export default CreateScreeningRequest;
