import React from "react";
import CreateScreeningRequest from "./screening_request";

class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
    this.state = {
      token: "",
      display: true,
      caseRef: "XXX-TEST",
    };

    this.handleTokenChange = this.handleTokenChange.bind(this);
    this.handleCaseRefChange = this.handleCaseRefChange.bind(this);
  }

  handleTokenChange(event) {
    this.setState({ token: event.target.value });
  }

  handleCaseRefChange(event) {
    this.setState({ caseRef: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log(`Token provided - ${this.state.token}`);
    console.log(`Case-Ref provided - ${this.state.caseRef}`);
    console.log(`Selected file - ${this.fileInput.current.files[0].name}`);
    CreateScreeningRequest(
      this.state.token,
      this.state.caseRef,
      this.fileInput.current.files[0]
    );
    //alert(`File submitted `);
  }

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <label>
            API Token:
            <input
              type="text"
              ref={this.state.token}
              onChange={this.handleTokenChange}
              style={{ marginBottom: `1rem`, marginLeft: `1rem` }}
            />
            <br />
            Case Ref:
            <input
              type="text"
              ref={this.state.caseRef}
              onChange={this.handleCaseRefChange}
              style={{ marginBottom: `1rem`, marginLeft: `1.5rem` }}
            />
            <br />
            Upload file:
            <input
              type="file"
              ref={this.fileInput}
              style={{ marginBottom: `1rem`, marginLeft: `0.8rem` }}
            />
            <br />
          </label>
          <br />
          <button type="submit">Submit</button>
        </form>
      </>
    );
  }
}

export default FileInput;
